import XLSX from 'xlsx';

export default {
  methods: {
    exportToExcel(rows, headers, fileName) {
      let formattedRows = [];

      
      //console.log(rows);
      if (rows.length > 0) {
        
        const self = this;
        rows.forEach(function (player) {
          
          let object = {};
          headers.forEach(h => {
            
            if (h.value !== "actions"){
            let split = h.value.split(".");
            let val = player;
            split.forEach(key => {
              if (val!== null && val !== undefined) 
              val = val[key];
            })
              object[h.text] = val;
            }
          });
          
          formattedRows.push(object)
        })
      }
      
      let data = XLSX.utils.json_to_sheet(formattedRows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data, fileName);
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    },
  },
}